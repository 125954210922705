import { ButtonPrimary, Form, InputTextarea, handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
    AppHeader,
    AppPage,
    LeadEntryFormFieldGroup,
    useSnackbar
} from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { LeadType } from '../../types';
import { CreateLeadEntryFormValidationSchema } from './LiveChat.validations';
import './create-lead-entry-modal.scss';

interface ChatCompany {
    chat_id: number;
    name: string;
    id: number;
    active: boolean;
    agency_id?: number;
}
export interface ChatLead {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    comments: string;
    live_chat_id: string;
    chat_company?: ChatCompany;
    is_agree_sms_notification: string;
}
export const LiveChatManual = observer(() => {

    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { Api, api } = store;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [chatCompanies, setChatCompanies] = useState<ChatCompany[]>();

    const initialValues: ChatLead = {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        live_chat_id: '',
        chat_company: undefined,
        comments: '',
        is_agree_sms_notification: 'No'
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const data = await api.fetch('live-chat/companies');
            setChatCompanies(data.data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };
    useAsyncEffect(fetchData);

    const handleSubmit = async (values: ChatLead, actions: FormikHelpers<ChatLead>) => {
        const { chat_company, ...rest } = values;
        const body = {
            lead_type: LeadType.LC,
            company_id: chat_company?.id,
            status: "CREATED_LIVE_CHAT_LEAD",
            ...rest
        };
        try {
            const [response, error] = await handlePromise<{ data: any }>(
                Api.client.post("leads", body)
            );
            if (
                !response?.data ||
                error
            ) {
                const errorMessage = error?.response?.data?.message || 'Failed to create lead.';
                addSnackbar(errorMessage, { variant: 'error' });
                return console.error('error: ', error);
            }
            addSnackbar('Lead created succesfully!', { variant: 'success' });
            actions.resetForm();
        } catch (error: unknown) {
            const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred.';
            addSnackbar(`Lead failed: ${errorMessage}`, { variant: 'error' });
            console.error('Error creating lead:', error);
        }
    };

    return (
        <>
            <AppPage loading={isLoading}>
                <AppHeader title="Live Chat" icon={{ name: 'chat', color: 'blue' }} />
                <div className='container'>
                    <Form
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={CreateLeadEntryFormValidationSchema}
                    >
                        {(formikProps: FormikProps<ChatLead>) => {
                            return (
                                <div className='sub-container'>
                                    <LeadEntryFormFieldGroup formikProps={formikProps} chatCompanies={chatCompanies} />
                                    <InputTextarea name="comments" label="Chat Transcript" formikProps={formikProps} />
                                    <div className='buttons'>
                                        <ButtonPrimary type="submit" disabled={formikProps.isSubmitting}>
                                            {formikProps.isSubmitting ? 'Creating lead...' : 'Create Lead'}
                                        </ButtonPrimary>
                                    </div>
                                </div>
                            );
                        }}
                    </Form>
                </div>
            </AppPage>
        </>
    );
});
