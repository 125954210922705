import * as Yup from 'yup';
import { FileUpload } from '../../components-v2/shared';
import { Company } from '../../types';
import { getFileUrl } from '../../util/file';
import { errorMessages } from '../../util/validations/errorMessages';

export enum EnablingSMSStatus {
    LOADING = 'loading',
    WAITING = 'waiting',
    ENABLING = 'enabling',
    DONE = 'done',
    ERROR = 'error'
}
export interface CompanyDetailsState {
    initialCompany: Company;
    fileUploading: boolean;
    enablingSMSStatus: EnablingSMSStatus;
    initialCompanyMembers?: CompanyAssignableUser[];
    companyMembers?: CompanyAssignableUser[];
    endTrialEarlyModal: boolean;
    trialDismissed: boolean;
    isCompanyActive: boolean;
    expirationDate?: string;
}

export interface CompanyAssignableUser {
    id: number;
    name: string;
    label?: string;
    email: string;
    role?: string;
}

export interface ApiUser {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    role: Record<string, any>;
}

const CompanyDetailsReducers = {
    setFileUploading: (state: CompanyDetailsState, payload: boolean) => ({
        ...state,
        fileUploading: payload
    }),
    setEnablingSMSStatus: (state: CompanyDetailsState, payload: boolean) => ({
        ...state,
        enablingSMSStatus: payload
    }),
    setInitialCompanyMembers: (state: CompanyDetailsState, payload: ApiUser[]) => ({
        ...state,
        initialCompanyMembers: payload.map(({ id, first_name, last_name, email, role }) => ({
            id,
            name: `${first_name} ${last_name || ''}`,
            email,
            role: role.name
        }))
    }),
    setCompanyMembers: (state: CompanyDetailsState, payload: ApiUser[]) => ({
        ...state,
        companyMembers: payload.map(({ id, first_name, last_name, email, role }) => ({
            id,
            name: `${first_name} ${last_name || ''}`,
            email,
            role: role.name
        }))
    }),
    addCompanyMember: (state: CompanyDetailsState, { id, name, email, role }: CompanyAssignableUser) => ({
        ...state,
        companyMembers: [{ id, name, email, role }, ...(state.companyMembers || [])]
    }),
    removeCompanyMember: (state: CompanyDetailsState, payload: CompanyAssignableUser) => {
        if (!state.companyMembers) return;
        const memberIndex = state.companyMembers.findIndex(member => member.id === payload.id);
        state.companyMembers.splice(memberIndex, 1);
        return { ...state };
    },
    resetCompanyMembers: (state: CompanyDetailsState, payload: undefined) => ({
        ...state,
        companyMembers: state.initialCompanyMembers
    }),
    toggleEndTrialModal: (state: CompanyDetailsState, payload: undefined) => {
        state.endTrialEarlyModal = !state.endTrialEarlyModal;
        return { ...state };
    },
    dismissTrial: (state: CompanyDetailsState, payload: undefined) => {
        state.trialDismissed = true;
        return { ...state };
    },
    toggleIsCompanyActive: (state: CompanyDetailsState, payload: undefined) => {
        state.isCompanyActive = !state.isCompanyActive;
        return { ...state };
    }
};

export type CompanyDetailsAction =
    | {
        name: 'setFileUploading';
        payload: boolean;
    }
    | {
        name: 'setEnablingSMSStatus';
        payload: EnablingSMSStatus;
    }
    | { name: 'setInitialCompanyMembers'; payload: ApiUser[] }
    | { name: 'setCompanyMembers'; payload: ApiUser[] }
    | { name: 'addCompanyMember'; payload: CompanyAssignableUser }
    | { name: 'removeCompanyMember'; payload: CompanyAssignableUser }
    | { name: 'resetCompanyMembers'; payload?: undefined }
    | { name: 'toggleEndTrialModal'; payload?: undefined }
    | { name: 'dismissTrial'; payload?: undefined }
    | { name: 'toggleIsCompanyActive'; payload?: undefined };

export const CompanyDetailsReducer = (state: CompanyDetailsState, action: CompanyDetailsAction) => {
    if (!CompanyDetailsReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: CompanyDetailsState = (CompanyDetailsReducers[action.name] as any)(state, action.payload);
    return nextState;
};

export const getCompanyLogoPreview = (company?: Company) =>
    company?.logo
        ? [
            {
                preview: getFileUrl(company.logo || '')
            }
        ]
        : [];

export interface CompanyDetailsFormValues {
    active: boolean;
    name: string;
    logo?: string | null;
    logo_file?: Partial<FileUpload>[];
    agency_id?: number;
    address1: string;
    address2?: string | null;
    city: string;
    state: string;
    zipcode: string;
    time_zone: string;
    website_url: string;
    dma: number | null;
    business_category_id?: number | null;
    is_test: boolean;
    is_universal: boolean;
    weekly_notification: boolean;
    weekly_lead_notification_email: string | null;
    lead_notification_primary_emails?: string;
    lead_notification_cc_emails: string | null;
    reply_to_email: string;
    phone?: string;
    crm_config_id?: number | null;
    ownership_group_id?: number | null;
    property_management_company_id?: number | null;
    region_id?: number | null;
    enquire_portal_id?: number | null;
    assigned_users?: CompanyAssignableUser[];
    ulm_company_ids?: Company[];
    expiration_date?: string | Date | null;
    live_chat_group_id?: number | null;
    is_forward_sms_enable?: boolean,
    sms_forward_phone_number?: string | null,
    sms_forward_notification_start_time?: string | null,
    sms_forward_notification_end_time?: string | null,

    // active: true
    // address1: "123 Pickles St"
    // address2: null
    // agency_id: 32
    // assigned_users: []
    // business_category_id: null
    // city: "San Marcos"
    // companyFeatures: []
    // created_at: "2021-05-03T19:20:17.909Z"
    // crm_config_id: null
    // crm_email: ""
    // dma: null
    // enquire_portal_id: null
    // forwardNumbers: []
    // hide_alerts: false
    // hide_alerts_users_ids: []
    // id: 767
    // include_company_name_in_email_header: false
    // is_test: false
    // lead_notification_cc_emails: null
    // lead_notification_primary_emails: "sadies@clxmedia.com"
    // logo: null
    // logo_mini: null
    // name: "Akira's World"
    // notification_bar_active: false
    // notification_bar_background_color: "#FF0000"
    // notification_bar_color: "#FFFFFF"
    // notification_bar_links: [{url: "", caption: ""}]
    // 0: {url: "", caption: ""}
    // notification_bar_message: null
    // ownership_group_id: 1
    // phone: ""
    // property_management_company_id: 1
    // region_id: 2
    // reply_to_email: ""
    // request_review_google: "If we provided top-notch service to you please consider taking a few seconds to leave us a review."
    // request_review_yelp: "If we provided top-notch service to you please consider taking a few seconds to leave us a review."
    // rev_link_google: ""
    // rev_link_yelp: ""
    // state: "TX"
    // time_zone: "America/Winnipeg"
    // trial_end_date: "2021-06-12T16:00:38.604Z"
    // updated_by: "sadies@clxmedia.com"
    // website_url: ""
    // weekly_lead_notification_email: "sadies@clxmedia.com"
    // weekly_notification: true
    // zipcode: "78666"
}

const transformStringToArray = (value: string, originalValue: string) => {
    return originalValue ? originalValue.trim().split(/[,;\s]+/) : [];
};

export const companyDetailsFormSchema = Yup.object().shape({
    active: Yup.bool().required(),
    logo: Yup.string().nullable(),
    expiration_date: Yup.date().nullable(),
    logo_file: Yup.object().shape({ preview: Yup.string() }).nullable(),
    name: Yup.string().required('Please enter a company name.'),
    address1: Yup.string().nullable().required('Please enter an address.'),
    address2: Yup.string().nullable(),
    city: Yup.string().nullable().required('Please enter a city.'),
    state: Yup.string().nullable().required('Please select a state.'),
    zipcode: Yup.string().nullable().required('Please enter a zip code.'),
    time_zone: Yup.string().nullable().required('Please select a time zone.'),
    website_url: Yup.string().url(errorMessages.url.required),
    agency_id: Yup.number().nullable(),
    dma: Yup.string().nullable(),
    business_category_id: Yup.number().nullable(),
    is_test: Yup.boolean(),
    weekly_notification: Yup.boolean(),
    weekly_lead_notification_email: Yup.string().nullable(),
    lead_notification_primary_emails: Yup.array()
        .transform(transformStringToArray)
        .of(Yup.string().email(errorMessages.commaSeparatedEmails.valid))
        .nullable(),
    lead_notification_cc_emails: Yup.array()
        .transform(transformStringToArray)
        .of(Yup.string().email(errorMessages.commaSeparatedEmails.valid))
        .nullable(),
    reply_to_email: Yup.string()
        .matches(/^[^,;\s]+$/, 'Please enter a single email address.')
        .email(errorMessages.email.valid)
        .required(errorMessages.email.required),
    phone: Yup.string().nullable(),
    crm_config_id: Yup.number().nullable(),
    ownership_group_id: Yup.number().nullable(),
    property_management_company_id: Yup.number().nullable(),
    region_id: Yup.number().nullable(),
    enquire_portal_id: Yup.number().nullable(),
    assigned_users: Yup.array().of(Yup.number()).nullable(),
    notification_start_time: Yup.string().notRequired().nullable(),
    notification_end_time: Yup.string()
        .notRequired()
        .nullable()
        .test(
            'is-after-start',
            'End time must be after start time',
            function (value) {
                const { notification_start_time } = this.parent;
                if (!notification_start_time) { return true }
                return value > notification_start_time;
            }
        ),
    forward_phone_number: Yup.string()
        .notRequired()
        .nullable()
        .matches(
            /^(\+?1\s?)?(\(\d{3}\)|\d{3})([-.\s]?)\d{3}([-.\s]?)\d{4}$/,
            'Invalid Phone number'
        ),
});
