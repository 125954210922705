export interface App {
    id: number;
    name: string;
    details: string;
}

export enum AppsName {
    SG = 'MODULE_SCHEDULEGENIE',
    CM = 'MODULE_CONCESSIONMANAGER',
    BPN = 'MODULE_BPN',
    IC = 'MODULE_INCOMECALCULATOR',
    CH = 'MODULE_CHAT',
    CB = 'MODULE_CHATBOT',
    TU = 'MODULE_TEXTUS',
    FS = 'MODULE_INSITE',
    CBAI = 'MODULE_CHATBOT_AI',
    LC = 'MODULE_LIVE_CHAT',
}
