import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { PageHeader } from 'react-bootstrap';
import { ScoreboardWidget, ScoreboardWidgetType } from './ScoreboardWidget';
import * as moment from 'moment-timezone';

const SEVEN_DAYS = 7;
const THIRTY_DAYS = 30;
const DATE_FORMAT_START = 'YYYY-MM-DD';
const DATE_FORMAT_END = 'YYYY-MM-DD HH:mm:ss';
const TOTAL_COMPANIES_COUNT = 'totalCompaniesCount';
const TOTAL_LEADS_COUNT = 'totalLeadsCount';
const TOTAL_LEADS_COUNT_SEVEN_DAYS = 'totalLeadsCountSevenDays';
const TOTAL_LEADS_COUNT_THIRTY_DAYS = 'totalLeadsCountThirtyDays';

@inject('store')
@observer
class Scoreboard extends React.Component<{}> {
    private scoreboardWidgetTypes = [
        {
            icon: 'briefcase',
            displayName: 'Companies',
            fetchMethod: 'fetchMetricsCompaniesCount',
            storeResultAs: TOTAL_COMPANIES_COUNT
        },
        {
            icon: 'fire',
            displayName: 'Total Leads',
            fetchMethod: 'fetchMetricsLeadsCount',
            storeResultAs: TOTAL_LEADS_COUNT
        },
        {
            icon: 'fire',
            displayName: 'Leads today',
            fetchMethod: 'fetchMetricsLeadsCount',
            fetchParams: {
                range: {
                    start: moment()
                        .startOf('day')
                        .format(DATE_FORMAT_START),
                    end: moment()
                        .endOf('day')
                        .format(DATE_FORMAT_END)
                }
            }
        },
        {
            icon: 'fire',
            displayName: 'Leads past 7 days',
            fetchMethod: 'fetchMetricsLeadsCount',
            fetchParams: {
                range: {
                    start: moment()
                        .subtract(SEVEN_DAYS, 'day')
                        .startOf('day')
                        .format(DATE_FORMAT_START),
                    end: moment()
                        .startOf('day')
                        .format(DATE_FORMAT_END)
                }
            },
            storeResultAs: TOTAL_LEADS_COUNT_SEVEN_DAYS
        },
        {
            icon: 'fire',
            displayName: 'Average leads per company over past 7 days',
            fetchMethod: null,
            fetchParams: null,
            calculateMethod: (instance: any) => {
                if (!instance[TOTAL_COMPANIES_COUNT] || !instance[TOTAL_LEADS_COUNT_SEVEN_DAYS]) {
                    return -1;
                }

                return this.toTwoDecimalPlaces(
                    instance[TOTAL_LEADS_COUNT_SEVEN_DAYS] / instance[TOTAL_COMPANIES_COUNT]
                );
            }
        },
        {
            icon: 'fire',
            displayName: 'Average leads per company per day over past 7 days',
            fetchMethod: null,
            fetchParams: null,
            calculateMethod: (instance: any) => {
                if (!instance[TOTAL_COMPANIES_COUNT] || !instance[TOTAL_LEADS_COUNT_SEVEN_DAYS]) {
                    return -1;
                }

                return this.toTwoDecimalPlaces(
                    instance[TOTAL_LEADS_COUNT_SEVEN_DAYS] / instance[TOTAL_COMPANIES_COUNT] / 7
                );
            }
        },
        {
            icon: 'fire',
            displayName: 'Leads past 30 days',
            fetchMethod: 'fetchMetricsLeadsCount',
            fetchParams: {
                range: {
                    start: moment()
                        .subtract(THIRTY_DAYS, 'day')
                        .startOf('day')
                        .format(DATE_FORMAT_START),
                    end: moment()
                        .startOf('day')
                        .format(DATE_FORMAT_END)
                }
            },
            storeResultAs: TOTAL_LEADS_COUNT_THIRTY_DAYS
        },
        {
            icon: 'fire',
            displayName: 'Average leads per company over past 30 days',
            fetchMethod: null,
            fetchParams: null,
            calculateMethod: (instance: any) => {
                if (!instance[TOTAL_COMPANIES_COUNT] || !instance[TOTAL_LEADS_COUNT_THIRTY_DAYS]) {
                    return -1;
                }

                return this.toTwoDecimalPlaces(
                    instance[TOTAL_LEADS_COUNT_THIRTY_DAYS] / instance[TOTAL_COMPANIES_COUNT]
                );
            }
        },
        {
            icon: 'fire',
            displayName: 'Average leads per company per day over past 30 days',
            fetchMethod: null,
            fetchParams: null,
            calculateMethod: (instance: any) => {
                if (!instance[TOTAL_COMPANIES_COUNT] || !instance[TOTAL_LEADS_COUNT_THIRTY_DAYS]) {
                    return -1;
                }

                return this.toTwoDecimalPlaces(
                    instance[TOTAL_LEADS_COUNT_THIRTY_DAYS] / instance[TOTAL_COMPANIES_COUNT] / 30
                );
            }
        },
        {
            icon: 'calendar',
            displayName: 'Active Schedule Genie Instances',
            fetchMethod: 'fetchMetricsCalendarsCount'
        },
        {
            icon: 'usd',
            displayName: 'Active Spotlight Instances',
            fetchMethod: 'fetchMetricsOffersCount'
        },
        {
            icon: 'road',
            displayName: 'Active Best Price Now Instances',
            fetchMethod: 'fetchMetricsBestPriceNowCount'
        },
        {
            icon: 'usd',
            displayName: 'Active Income Calculator Instances',
            fetchMethod: 'fetchMetricsIncomeCalculatorCount'
        },
        {
            icon: 'phone',
            displayName: 'Active Live Chat Instances',
            fetchMethod: 'fetchMetricsChatCount'
        },
        {
            icon: 'phone',
            displayName: 'Active Text Us Now Instances',
            fetchMethod: 'fetchMetricsTextUsNowCount'
        },
        {
            icon: 'fire',
            displayName: 'Top 5 Companies By Leads All Time',
            fetchMethod: 'fetchTopNCompanies',
            fetchParams: {
                range: null,
                numCompanies: 5
            },
            displayCols: 6
        },
        {
            icon: 'fire',
            displayName: 'Top 5 Companies By Leads Last 7 Days',
            fetchMethod: 'fetchTopNCompanies',
            fetchParams: {
                range: {
                    start: moment()
                        .subtract(SEVEN_DAYS, 'day')
                        .startOf('day')
                        .format(DATE_FORMAT_START),
                    end: moment()
                        .endOf('day')
                        .format(DATE_FORMAT_END)
                },
                numCompanies: 5
            },
            displayCols: 6
        },
        {
            icon: 'fire',
            displayName: 'Top 5 Companies By Leads Last 30 Days',
            fetchMethod: 'fetchTopNCompanies',
            fetchParams: {
                range: {
                    start: moment()
                        .subtract(THIRTY_DAYS, 'day')
                        .startOf('day')
                        .format(DATE_FORMAT_START),
                    end: moment()
                        .endOf('day')
                        .format(DATE_FORMAT_END)
                },
                numCompanies: 5
            },
            displayCols: 6
        },
        {
            icon: 'fire',
            displayName: 'Bottom 5 Companies By Leads All Time',
            fetchMethod: 'fetchBottomNCompanies',
            fetchParams: {
                range: null,
                numCompanies: 5
            },
            displayCols: 6
        },
        {
            icon: 'fire',
            displayName: 'Matchbacks By Company All Time',
            fetchMethod: 'fetchMatchbacksByCompany',
            fetchParams: {
                range: null,
                numCompanies: 10
            },
            displayCols: 6
        },
        {
            icon: 'fire',
            displayName: 'Matchbacks By PMC All Time',
            fetchMethod: 'fetchMatchbacksByPMC',
            fetchParams: {
                range: null,
                numCompanies: 10
            },
            displayCols: 6
        },
        {
            icon: 'fire',
            displayName: 'Matchbacks By Week All Time',
            fetchMethod: 'fetchMatchbacksByWeek',
            fetchParams: {
                range: null,
                numCompanies: 10
            },
            displayCols: 6
        },
        {
            icon: 'fire',
            displayName: 'Leads Count By Day All Time',
            fetchMethod: 'fetchLeadsCountByDay',
            fetchParams: {
                range: null,
                numRows: 10
            },
            displayCols: 6
        },
        {
            icon: 'fire',
            displayName: 'Leads Count By Month All Time',
            fetchMethod: 'fetchLeadsCountByMonth',
            fetchParams: {
                range: null,
                numRows: 10
            },
            displayCols: 6
        }
    ];

    public render() {
        return (
            <>
                <PageHeader>Scoreboard</PageHeader>
                <div className="holder-scoreboard__box">
                    {this.scoreboardWidgetTypes.map((scoreboardWidgetType: ScoreboardWidgetType) => {
                        return <ScoreboardWidget key={scoreboardWidgetType.displayName} type={scoreboardWidgetType} />;
                    })}
                </div>
            </>
        );
    }

    private toTwoDecimalPlaces(num: number) {
        return Math.round(num * 100) / 100;
    }
}

export default Scoreboard;
