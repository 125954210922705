import * as Yup from 'yup';

export const LivechatValidationSchema = () =>
    Yup.object({
        call_to_action: Yup.string().required('Please add a Call to Action.').min(4, 'Call to Action must be above 4 characters.').max(60, 'Call to Action may only be 60 characters.')
    });

export const CreateLeadEntryFormValidationSchema = () =>
    Yup.object().shape({
        first_name: Yup.string()
            .max(15, 'First name cannot exceed 15 characters.')
            .required('Please enter a first name.'),
        last_name: Yup.string()
            .max(15, 'Last name cannot exceed 15 characters.')
            .required('Please enter a last name.'),
        email: Yup.string()
            .email('Please enter a valid email address.')
            .max(254, 'Email cannot exceed 254 characters.')
            .required('Please enter an email address.'),
        phone_number: Yup.string().required('Please enter a phone number.'),
        chat_company: Yup.string().required('Please select a client.'),
        live_chat_id: Yup.string().required('Live chat id required.'),
        comments: Yup.string()
            .max(3000, 'Comments cannot exceed 3,000 characters.')
            .notRequired()
    });



