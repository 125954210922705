export const chartColour = {
    SG: '#5cb85c',
    CM: '#3174ad',
    BPN: 'orange',
    IC: '#EF5350',
    CH: '#9999ff',
    TU: '#e6b800',
    IN: '#3bc3ed'
};

export const LeadTypeAbbr = {
    SCHEDULE_GENIE: 'SG',
    CONCESSION_MANAGER: 'CM',
    INCOME_CALCULATOR: 'IC',
    BPN: 'BPN',
    CHAT: 'CH',
    TEXT_US: 'TU',
    INSITE: 'IN',
};
