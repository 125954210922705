import React, { useReducer } from 'react';
import { observer } from 'mobx-react';
import { CircularProgress } from '@material-ui/core';
import { useAsyncEffect, handlePromise, PieChart, TimeChart } from '@lambdacurry/component-library';
import {
    AppCardGrid,
    AppListLoading,
    AppSectionHeader,
    ChartCard,
    StatCard,
    useSnackbar
} from '../../components-v2/shared';
import useStore from '../../store/useStore';
import {
    dashboardApps,
    DashboardData,
    DashboardInitialState,
    HomeDashboardReducer,
    getArrayItemFromNumber,
    dashboardPieChartColors,
    HomeDashboardLoadingStatus
} from './HomeDashboard.helpers';
import { AppIcons } from '../../constants/apps';

import { numberFormatter } from '../../util/formatters';
import { LeadTypeNames } from '../../types/Lead';

import './home-dashboard.scss';
import { FiltersSelector, useFilters } from '../Filters/Filters';
import DefaultRoutes from '../../routes/DefaultRoutes';

export const HomeDashboard = observer(() => {
    const { store, agencyStore } = useStore();
    const { addSnackbar } = useSnackbar();
    const { Api, activeCompanyId, isChatRole, setActiveCompanyId } = store;
    const { activeAgencyId } = agencyStore;

    if (isChatRole) {
        store.router.goTo(DefaultRoutes.LiveChatManualLeadEntrySubmission, {}, store, {});
        return null;
    }
    if (isChatRole) {
        store.router.goTo(DefaultRoutes.LiveChatManualLeadSubmission, {}, store, {});
        return null;
    }

    const [state, dispatch] = useReducer(HomeDashboardReducer, DashboardInitialState);
    const { filters } = useFilters();

    // Re-fetch analytics data whenever the filters, context, activeCompanyId, or activeAgencyId changes.
    const fetchAnalyticsData = async () => {
        await setActiveCompanyId(0)
        dispatch({ name: 'setLoadingStatus', payload: HomeDashboardLoadingStatus.loading });
        if (!filters) {
            return;
        }
        const [response, error] = await handlePromise<{ data: DashboardData }>(
            Api.client.post('/analytics/dashboard', { filter: filters })
        );

        if (!response?.data || error) {
            dispatch({ name: 'setLoadingStatus', payload: HomeDashboardLoadingStatus.error });
            addSnackbar('Failed to fetch dashboard stats data.', { variant: 'error' });
            return console.error('error: ', error);
        }

        dispatch({ name: 'setLoadingStatus', payload: HomeDashboardLoadingStatus.complete });
        dispatch({ name: 'setPageData', payload: { ...response.data } });
    };
    useAsyncEffect(fetchAnalyticsData, undefined, [filters, activeCompanyId, activeAgencyId]);

    const dailyLeadsData = Object.entries(state.data?.dailyLeads || {})
        .sort((a, b) =>
            dashboardApps.findIndex(app => app.type === a[0]) > dashboardApps.findIndex(app => app.type === b[0])
                ? 1
                : -1
        )
        .map((dailyLead, index) => ({
            label: LeadTypeNames[dailyLead[0]] as string,
            data: dailyLead[1],
            color: getArrayItemFromNumber(index + 1, dashboardPieChartColors) as string
        }));

    const sessionsPerSourceData = state.data?.clarityReports.sessionsPerSource.sort(
        (sessionA, sessionB) => sessionB.source_count - sessionA.source_count
    );

    const conversionsByApplicationData = Object.keys(LeadTypeNames)
        .map(type => ({
            value: state.data?.totals.leadsByModule[type],
            label: LeadTypeNames[type]
        }))
        .filter(item => item.value !== undefined)
        .sort(({ value: valueA }, { value: valueB }) => valueB - valueA)
        .map((item, index) => ({
            ...item,
            color: getArrayItemFromNumber(index + 1, dashboardPieChartColors)
        }));

    const isConversionsByApplicationEmpty = !!conversionsByApplicationData.find(item => item.value > 0);

    return (
        <div className="home-dashboard">
            <AppSectionHeader title="Leads">
                <div className="filters">
                    {state.loadingStatus === HomeDashboardLoadingStatus.loading && (
                        <div className="filters-loading">
                            <CircularProgress size="20px" />
                        </div>
                    )}
                    <FiltersSelector />
                </div>
            </AppSectionHeader>

            {state.loadingStatus === HomeDashboardLoadingStatus.loading && (
                <AppListLoading title="Retrieving analytics data" />
            )}

            {state.data && state.loadingStatus === HomeDashboardLoadingStatus.complete && (
                <>
                    <AppCardGrid>
                        <StatCard
                            label="Sessions"
                            value={`${numberFormatter(state.data.totals.sessions)}`}
                            color="green"
                            icon="eye"
                            helpText="When a user opens or loads a web page containing our marketing module. The session expires after 30 minutes of inactivity. If a user opens the same web page 30 minutes later, this is a new session."
                        />
                        <StatCard
                            label="App Interactions"
                            value={`${numberFormatter(state.data.totals.interactions)}`}
                            color="green"
                            icon="touch"
                            helpText="A user clicking our marketing module to activate a specific application for example Schedule Genie. The interactions counter increments only when opening our marketing module from a closed state."
                        />
                        <StatCard
                            label="Conversions"
                            value={`${numberFormatter(state.data.totals.conversionRate * 100)}%`}
                            color="green"
                            icon="deal"
                            helpText="The total number of leads divided by the total number of sessions. For example, there is one lead and 100 sessions. The conversion rate is 1% or 1/100."
                        />
                        <StatCard
                            label="Total Leads"
                            value={`${numberFormatter(state.data.totals.leads)}`}
                            color="green"
                            icon="flame"
                            helpText="When a user submits their name, email, and phone number using our marketing module."
                        />

                        {dashboardApps.map(({ type, label }) => {
                            if (state.data?.totals.leadsByModule[type] == null) {
                                return null;
                            }

                            return (
                                <StatCard
                                    key={type}
                                    label={label}
                                    value={numberFormatter(state.data?.totals.leadsByModule[type])}
                                    color="blue"
                                    icon={AppIcons[type]}
                                />
                            );
                        })}

                        <ChartCard type="line">
                            <AppSectionHeader title="Leads by day" />
                            <TimeChart
                                width={200}
                                height={50}
                                datasets={dailyLeadsData}
                                options={{
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    fontSize: 14,
                                                    fontFamily: 'clx-gilroy, sans-serif'
                                                }
                                            }
                                        ],
                                        xAxes: [
                                            {
                                                ticks: {
                                                    fontSize: 14,
                                                    fontFamily: 'clx-gilroy, sans-serif'
                                                }
                                            }
                                        ]
                                    }
                                }}
                            />
                        </ChartCard>

                        <ChartCard title="Sessions per Source" isEmpty={!sessionsPerSourceData?.length}>
                            {!!sessionsPerSourceData?.length && sessionsPerSourceData?.length > 0 && (
                                <PieChart
                                    type="doughnut"
                                    data={sessionsPerSourceData.map(({ utm_source, source_count }, index) => ({
                                        value: source_count,
                                        label: utm_source,
                                        color: getArrayItemFromNumber(index + 1, dashboardPieChartColors)
                                    }))}
                                />
                            )}
                        </ChartCard>

                        <ChartCard title="Conversions by Application" isEmpty={!isConversionsByApplicationEmpty}>
                            {isConversionsByApplicationEmpty && (
                                <PieChart type="doughnut" data={conversionsByApplicationData} />
                            )}
                        </ChartCard>
                    </AppCardGrid>
                </>
            )}
        </div>
    );
});
