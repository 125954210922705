import React, { useReducer, useState } from 'react';
import { observer } from 'mobx-react';
import {
    AppContent,
    AppHeader,
    AppPage,
    ButtonOutlinePrimary,
} from '../../components-v2/shared';
import useStore from '../../store/useStore';
import {
    liveChatReducer,
    liveChatInitialState,
    openModal,
    closeModal,
    setLoading
} from './LiveChatLegacy.helpers';

import { Form, InputSelect, useAsyncEffect } from '@lambdacurry/component-library';
import { CreateChatLeadModal } from './components/CreateChatLeadModal/CreateChatLeadModal';
import { FormikProps } from 'formik';
import { ChatKeys } from '../../types';

interface ChatCompany {
    chat_id: number;
    name: string;
    id: number;
    active: boolean;
    agency_id?: number;
}

export const LiveChatLegacyManual = observer(() => {
    const [state, dispatch] = useReducer(liveChatReducer, liveChatInitialState);
    const [chatCompanies, setChatCompanies] = useState<ChatCompany[]>();
    const { store } = useStore();
    const { api } = store;
    const [chatKeys, setChatKeys] = useState<ChatKeys>();

    const fetchData = async () => {
        const data = await api.fetch('chats/companies');
        console.log(JSON.stringify(data.data));
        setChatCompanies(data.data);
        setLoading(dispatch, false);
    };
    useAsyncEffect(fetchData);

    return (
        <>                <Form
            initialValues={{}}
            onSubmit={() => { }}
        >
            {(formikProps: FormikProps<{ chat_company?: ChatCompany }>) => (
                <AppPage loading={state.loading}>
                    <AppHeader title="Live Chat Legacy" icon={{ name: 'chat', color: 'blue' }} />
                    <AppContent>
                        {chatCompanies && (
                            <InputSelect
                                style={{ width: 600 }}
                                name="chat_company"
                                options={chatCompanies || []}
                                optionLabelKey='name'
                                formikProps={formikProps}
                                label='Chat Company'
                            />
                        )}
                        {!!formikProps.values.chat_company && (
                            <ButtonOutlinePrimary
                                onClick={() => {
                                    if (!!formikProps.values.chat_company) {
                                        setChatKeys({ id: formikProps.values.chat_company.chat_id, company_id: formikProps.values.chat_company.id });
                                        openModal(dispatch, 'createChatLead');
                                    }
                                }}
                                data-test-id="button_chat_create_lead"
                            >
                                Create Lead for {formikProps.values.chat_company.name}
                            </ButtonOutlinePrimary>
                        )}

                    </AppContent>
                </AppPage>
            )}
        </Form>



            {chatKeys && (
                <CreateChatLeadModal
                    chat={chatKeys}
                    isOpen={state.modal.active === 'createChatLead'}
                    dispatch={dispatch}
                    onAfterClose={() => closeModal(dispatch)}
                />
            )}
        </>
    );
});
