import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import {
    ActionList,
    AppFooter,
    AppSection,
    AppSectionHeader,
    Button,
    ButtonOutlinePrimary,
    ButtonPrimary,
    Form,
    InputText
} from '../../../../components-v2/shared';

import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import useStore from '../../../../store/useStore';
import { Crm } from '../../../../types/Crm';

interface EldermarkV2Crm extends Crm {
    configuration: {
        api_key: string;
        company_id: string;
        referral_source_name: string;
        referral_source_id: number;
        community_id: string;
        subdomain: string;
    };
}

export interface EldermarkFormProps {
    initialValues: EldermarkV2Crm;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

export const EldermarkV2Form: FC<EldermarkFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<EldermarkV2Crm>) => {
        await onValidate(formikProps.values, crmConfigTypes.eldermark_v2);
    };

    const handleSubmit = async (values: EldermarkV2Crm, actions: FormikHelpers<EldermarkV2Crm>) => {
        await onSubmit(values, actions, crmConfigTypes.eldermark_v2);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<EldermarkV2Crm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Eldermark V2" />

                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader
                                    title="Eldermark V2 Configuration"
                                    subtitle="Contact Eldermark V2 support for the access token and workflow ID."
                                />
                                <InputText
                                    name="configuration.subdomain"
                                    label="Subdomain"
                                    required={true}
                                    helperText="Eldermark Subdomain"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.company_id"
                                    label="Company ID"
                                    required={true}
                                    helperText="Company ID"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.referral_source_name"
                                    label="Referral Source Name"
                                    required={true}
                                    helperText="Referral Source Name"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.referral_source_id"
                                    label="Referral Source ID"
                                    required={true}
                                    helperText="Referral Source ID"
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.community_id"
                                    label="Community ID"
                                    helperText="Community ID"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <InputText
                                    name="configuration.api_key"
                                    label="API Key"
                                    helperText="Eldermark API Key"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </div>
                        </AppSection>

                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
