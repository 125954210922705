import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { SideNavMenu } from '../../SideNavMenu';
import { SideNavMenuItem } from '../../SideNavMenuItem';
import DefaultRoutes from '../../../../../routes/DefaultRoutes';
import useStore from '../../../../../store/useStore';
import { getSupportUrl } from '../../SideNav.helpers';
import { getRouteWithContext } from '../../../../../routes/routeUtils';

export interface MenuAdminProps { }

export const MenuAdmin: FC<MenuAdminProps> = observer(props => {
    const { store } = useStore();
    const { hasAccess, router } = store;

    const leadRoutes = [{ route: DefaultRoutes.LeadView }];
    const leadsItems = [...leadRoutes, { route: DefaultRoutes.InsiteMetricDataList }];

    const clarityV2 = { route: getRouteWithContext('ClarityDashboard', router) };

    const settingsItems = [
        { route: DefaultRoutes.CategoryList },
        { route: DefaultRoutes.HolidaysList },
        { route: DefaultRoutes.PropertyManagementCompanyList },
        { route: DefaultRoutes.OwnershipGroupList },
        { route: DefaultRoutes.RegionsList },
        { route: DefaultRoutes.AppointmentTypeList },
        { route: DefaultRoutes.GlobalQuestionDefinitionPresetPage }
    ];

    const toolsItems = [
        { route: DefaultRoutes.CompanyLists },
        { route: DefaultRoutes.EmailViewer },
        { route: DefaultRoutes.Messages },
        { route: DefaultRoutes.OptOutQueueList },
        { route: DefaultRoutes.AuditLog },
        { route: DefaultRoutes.CssToolsList },
        { route: DefaultRoutes.CsvImport }
    ];

    return (
        <SideNavMenu>
            <SideNavMenuItem name="dashboard" icon="home" route={DefaultRoutes.Home} />

            {hasAccess('leads.view') && (
                <SideNavMenuItem
                    name="leads"
                    label="Leads"
                    icon="user"
                    route={leadsItems[0].route}
                    submenu={{ items: leadsItems }}
                />
            )}

            {hasAccess('live_chat.view') && (
                <SideNavMenuItem
                    name="liveChat"
                    label="Live Chat"
                    icon="chat"
                    route={DefaultRoutes.LiveChatManualLeadEntrySubmission}
                />
            )}

            {hasAccess('live_chat.view') && (
                <SideNavMenuItem
                    name="liveChatLegacy"
                    label="Live Chat Legacy"
                    icon="chat"
                    route={DefaultRoutes.LiveChatManualLeadSubmission}
                />
            )}

            {hasAccess('admin.dashboard') && (
                <SideNavMenuItem name="clarity_v2" label="CLarity Attribution" icon="analytics" route={clarityV2.route} />
            )}

            {hasAccess('scoreboard.view') && (
                <SideNavMenuItem
                    name="scoreboard"
                    label="Scoreboard"
                    icon="scoreboard"
                    route={DefaultRoutes.Scoreboard}
                />
            )}

            <li className="side-nav-divider" />

            {hasAccess('users.view') && (
                <SideNavMenuItem name="users" label="Users" icon="users" route={DefaultRoutes.UserList} />
            )}

            {hasAccess('admin.dashboard') && (
                <SideNavMenuItem
                    name="settings"
                    label="Settings"
                    icon="settings"
                    route={settingsItems[0].route}
                    submenu={{ items: settingsItems }}
                />
            )}

            {hasAccess('admin.dashboard') && (
                <SideNavMenuItem
                    name="tools"
                    label="Tools"
                    icon="filter"
                    route={toolsItems[0].route}
                    submenu={{ items: toolsItems }}
                />
            )}

            <SideNavMenuItem
                name="support"
                label="Support"
                icon="support"
                onClick={() => window.open(getSupportUrl(store), '_blank')}
            />
        </SideNavMenu>
    );
});
