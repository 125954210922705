import React, { FunctionComponent } from 'react';
import { AppSectionHeader, InputColor, InputSelect, InputText } from '../../../components-v2/shared';
import { FormikProps } from 'formik';
import { EditAppEmailFormValues, EmailResponse, EmailSection } from '../EmailEditor.types';

export const EditEmailCallToActionForm: FunctionComponent<{
    section: EmailSection;
    formikProps: FormikProps<EditAppEmailFormValues>;
    response: EmailResponse;
}> = ({ formikProps, section, response }) => {
    const availableColors = (selectedColor: string | null) => {
        const baseColors = response.picklists?.colors || [];
        if (!selectedColor) {
            return baseColors;
        }

        return [null, ...baseColors.filter(color => color !== selectedColor)];
    };

    return (
        <>
            <InputText
                id="ctaText"
                name={`${section.id}.ctaText`}
                label="Call To Action Button Text"
                inputProps={{ maxLength: 78 }}
                formikProps={formikProps}
            />
            <InputSelect
                id="ctaLink"
                name={`${section.id}.ctaLink`}
                label="Call To Action Link"
                inputProps={{ maxLength: 130 }}
                optionValueKey="value"
                optionLabelKey="label"
                options={[
                    { value: '{{{companyWebsiteLink}}}', label: 'Link to Homepage' },
                    { value: '{{{scheduleGenieAppLink}}}', label: 'Link to Schedule Genie' },
                    { value: '{{{concessionManagerAppLink}}}', label: 'Link to Spotlight' },
                    { value: '{{{bestPriceNowAppLink}}}', label: 'Link to Best Price Now' },
                    { value: '{{{textUsNowAppLink}}}', label: 'Link to Text Us Now' },
                    { value: '{{{chatAppLink}}}', label: 'Link to Chat' },
                    { value: '{{{incomeCalculatorAppLink}}}', label: 'Link to Income Calculator' }
                ]}
                formikProps={formikProps}
                autoComplete="off"
                allowCreateOption={true}
            />
            <InputSelect
                name={`${section.id}.ctaButtonTextColor`}
                label="Call To Action Button Text Color"
                optionValueKey="value"
                optionLabelKey="label"
                options={[
                    { value: '#FFFFFF', label: 'White' },
                    { value: '#000000', label: 'Black' }
                ]}
                formikProps={formikProps}
            />
            <InputColor
                name={`${section.id}.ctaButtonColor`}
                label="Call To Action Button Color"
                formikProps={formikProps}
            />
            {availableColors(formikProps.values[`${section.id}`].ctaButtonColor).length > 0 && (
                <>
                    <AppSectionHeader title="Color Gallery" />
                    {availableColors(formikProps.values[`${section.id}`].ctaButtonColor).map((color, i) => (
                        <div
                            onClick={() => {
                                formikProps.setFieldValue(`${section.id}.ctaButtonColor`, color);
                            }}
                            style={{
                                border: '1px solid #dddddd',
                                borderRadius: '5px',
                                padding: '12px',
                                cursor: 'grab',
                                marginBottom: '8px'
                            }}
                        >
                            <span
                                style={{
                                    backgroundColor: color || 'transparent',
                                    position: 'absolute',
                                    width: '24px',
                                    height: '24px'
                                }}
                            ></span>
                            <span
                                style={{
                                    position: 'relative',
                                    left: '32px'
                                }}
                            >
                                {color || 'Remove Custom Color'}
                            </span>
                        </div>
                    ))}
                </>
            )}
        </>
    );
};
