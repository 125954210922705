import React, { FC } from 'react';
import { FormikHelpers } from 'formik';
import { observer } from 'mobx-react';
import { Crm } from '../../../types/Crm';
import { crmConfigTypes } from '../../../constants';
import { AdfForm } from './forms/AdfForm';
import { EldermarkForm } from './forms/EldermarkForm';
import { EnquireForm } from './forms/EnquireForm';
import { EntrataForm } from './forms/EntrataForm';
import { HospiceMDForm } from './forms/HospiceMDForm';
import { KnockForm } from './forms/KnockForm';
import { MeetEliseForm } from './forms/MeetEliseForm';
import { RealPageForm } from './forms/RealPageForm';
import { RealPageOneSiteForm } from './forms/RealPageOneSiteForm';
import { RealPageLead2LeaseForm } from './forms/RealPageLead2LeaseForm';
import { RentManagerForm } from './forms/RentManagerForm';
import { YouveGotLeadsForm } from './forms/YouveGotLeadsForm';
import { SherpaForm } from './forms/SherpaForm';
import { RentCafeForm } from './forms/RentCafeForm';
import { RentCafeFormV2 } from './forms/RentCafeFormV2';
import { YardiForm } from './forms/YardiForm';
import { YardiSoapForm } from './forms/YardiSoapForm';
import { YardiSippForm } from './forms/YardiSippForm';
import { WelcomeHomeForm } from './forms/WelcomeHomeForm';
import { FunnelForm } from './forms/FunnelForm';
import { GlennisSolutionsForm } from './forms/GlennisSolutionsForm';
import { RentDynamicsForm } from './forms/RentDynamicsForm';
import { AnyoneHomeForm } from './forms/AnyoneHomeForm';
import { ZohoForm } from './forms/ZohoForm';
import { ExacareForm } from './forms/ExacareForm';
import { AlisForm } from './forms/AlisForm';
import { FortressForm } from './forms/FortressForm';
import { HubSpotForm } from './forms/HubSpotForm';
import { ResManForm } from './forms/ResManForm';
import { EldermarkV2Form } from './forms/EldermarkV2Form';

export interface EditCrmFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
    crmType: string;
}

export const EditCrmForm: FC<EditCrmFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    return (
        <>
            {props.crmType === crmConfigTypes.adf && (
                <AdfForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                />
            )}
            {props.crmType === crmConfigTypes.eldermark && (
                <EldermarkForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.eldermark_v2 && (
                <EldermarkV2Form
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.enquire && (
                <EnquireForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.entrata && (
                <EntrataForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.anyone_home && (
                <AnyoneHomeForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.hospice_md && (
                <HospiceMDForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                />
            )}
            {props.crmType === crmConfigTypes.knock && (
                <KnockForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                />
            )}
            {props.crmType === crmConfigTypes.meet_elise && (
                <MeetEliseForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                />
            )}
            {props.crmType === crmConfigTypes.realpage && (
                <RealPageForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                />
            )}
            {props.crmType === crmConfigTypes.realpage_onesite && (
                <RealPageOneSiteForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.realpage_lead2lease && (
                <RealPageLead2LeaseForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.rent_manager && (
                <RentManagerForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.welcome_home && (
                <WelcomeHomeForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.funnel && (
                <FunnelForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.ygl && (
                <YouveGotLeadsForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.sherpa && (
                <SherpaForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.rentcafe && (
                <RentCafeForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.rentcafe_v2 && (
                <RentCafeFormV2
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.yardi && (
                <YardiForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                />
            )}
            {props.crmType === crmConfigTypes.yardi_soap && (
                <YardiSoapForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.yardi_sipp && (
                <YardiSippForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.glennis_solutions && (
                <GlennisSolutionsForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.rent_dynamics && (
                <RentDynamicsForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.zoho && (
                <ZohoForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.exacare && (
                <ExacareForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.alis && (
                <AlisForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.fortress && (
                <FortressForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.hubspot && (
                <HubSpotForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
            {props.crmType === crmConfigTypes.resman && (
                <ResManForm
                    initialValues={props.initialValues}
                    enableReinitialize={props.enableReinitialize}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                />
            )}
        </>
    );
});
