import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { InputPhone, InputSelect, InputText } from '..';

interface ChatCompany {
    chat_id: number;
    name: string;
    id: number;
    active: boolean;
    agency_id?: number;
}
export interface LeadCustomerFieldGroupProps {
    formikProps: FormikProps<any>;
    chatCompanies: ChatCompany[] | any
}

export const LeadEntryFormFieldGroup: FC<LeadCustomerFieldGroupProps> = ({ formikProps, chatCompanies }) => {

    return (
        <div className="field-group-content" >
            <div className="field-group-col">
                <InputSelect
                    style={{ width: 600 }}
                    name="chat_company"
                    options={chatCompanies || []}
                    optionLabelKey='name'
                    formikProps={formikProps}
                    label='Chat Company*'
                />
                <InputText name="first_name" label="First Name*" formikProps={formikProps} />
                <InputText name="email" label="Email Address*" formikProps={formikProps} />
            </div>
            <div className="field-group-col">
                <InputText name="live_chat_id" label="Chat Id*" formikProps={formikProps} />
                <InputText name="last_name" label="Last Name*" formikProps={formikProps} />
                <InputPhone name="phone_number" label="Phone Number*" formikProps={formikProps} />
            </div>

        </div>
    );
};
